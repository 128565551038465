<template>
  <section class="all-strains">

    <div class="filters">
        <select v-for="(terms, taxonomy) in taxonomyFilters"
                :id="taxonomy"
                :name="taxonomy"
                class="filter"
                :key="taxonomy"
                @change.prevent="filterBy($event, taxonomy)">
          <option value="" :selected="termFilter[taxonomy] === null" disabled>Filter by {{taxonomyNames[taxonomy]}}</option>
          <option v-for="term in terms"
                  :value="term.slug"
                  :key="term.slug"
                  >{{ term.name }}</option>
        </select>
        <button class="filters__clear" @click.prevent="clearFilters()">Clear</button>
    </div>

    <div class="all-strains-list">
      <template v-for="(post, index) in filteredPosts" :key="index">
        <div class="block">
          <div class="block-header">
            <span class="badge">{{ post.genetic_makeup }}</span>
          </div>
          <div class="block-body">
            <h4>
              <a :href="post.url">{{ post.title }}</a>
            </h4>
            <div class="block-image">
              <a :href="post.url">
                <img :src="post.flower_image" :alt="post.flower_alt">
              </a>
            </div>
            <p v-html="post.excerpt"></p>
          </div>
          <div class="block-footer">
            <a class="button" :href="post.url">View Strain</a>
          </div>
        </div>
      </template>
    </div>

  </section>
</template>

<script>
export default {
    props: {
        posts: {
            type: Array,
        },
        taxonomies: {
            type: Array,
        },
        taxonomyNames: {
            type: Object,
        },
        queryParams: {
            type: Object
        }
    },

    data() {
        return {
            termFilter: this.clearFilters(true),
        };
    },

    mounted() {
        if(this.queryParams !== {} && this.queryParams !== null && this.queryParams !== undefined) {
            Object.keys(this.queryParams).forEach(taxonomy => {
                let termIndex = this.taxonomyFilters[taxonomy].findIndex((term) => term.slug === this.queryParams[taxonomy]);
                this.termFilter[taxonomy] = this.taxonomyFilters[taxonomy][termIndex];
            });
        }
    },

    computed: {
        filteredPosts() {
            let taxonomies = [];

            Object.keys(this.taxonomyNames).forEach(taxonomy => {
                if (this.termFilter[taxonomy] !== null) {
                    taxonomies.push(taxonomy);
                }
            });

            // All filters are null so do not apply a filter
            if(taxonomies.length === 0) {
                return this.alphabetizeByTitle(this.posts);
            }

            return this.alphabetizeByTitle(
                this.posts.filter((post) => {
                    for (let taxonomy of taxonomies) {
                        if(!this.postHasTerm(post, this.termFilter[taxonomy].term_id)) {
                            return false;
                        }
                    }

                    return true;
                })
            );
        },

        taxonomyFilters() {
            let filters = {};

            for (let taxonomy of this.taxonomies) {
                for (let term of taxonomy) {
                    if (!filters.hasOwnProperty(term.taxonomy)) {
                        filters[term.taxonomy] = [];
                    }
                    filters[term.taxonomy] = [...filters[term.taxonomy], term];
                }
            }
            return filters;
        }
    },
    methods: {
        filterBy(event, taxonomy) {
            let termIndex = this.taxonomyFilters[taxonomy].findIndex((term) => term.slug === event.target.value);
            this.termFilter[taxonomy] = this.taxonomyFilters[taxonomy][termIndex];
        },

        clearFilters(bool) {
            let newFilters = {};

            Object.keys(this.taxonomyNames).forEach(taxonomy => {
            newFilters[taxonomy] = null;
            });

            if(bool) {
                return newFilters;
            }

            this.termFilter = newFilters;
        },

        postHasTerm(post, termId) {
            if (post.hasOwnProperty('terms') && Array.isArray(post.terms)) {
                return post.terms.map(term => term.term_id).includes(termId);
            }
            return false;
        },

        alphabetizeByTitle(posts) {
            let postTitles = [];
            let sortedPosts = [];

            posts.forEach(post => {
                postTitles.push(post.title);
            })

            // Alphabetically sort post title list
            postTitles.sort();

            posts.forEach(post => {
                let sortedPostIndex = postTitles.indexOf(post.title);
                sortedPosts[sortedPostIndex] = post;
            });

            return sortedPosts;
        },
    },
}
</script>